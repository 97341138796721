import { autoinject, bindable } from 'aurelia-framework';
import { Toast } from '../../../../utilities/toast';
import Swal from 'sweetalert2';
import notie from 'notie';
import {
    AlertType,
    CaseBook,
    CaseBookLightweightExtended,
    CaseBookReferencedEntity,
    CaseBookReferencedEntityTypes,
    CasesApiClient,
    CloseBulkThreatsRequest,
    FlagBulkThreatsRequest,
    IgnoreBulkThreatsRequest,
    ThreatsApiClient,
    ThreatSummary,
    UnflagBulkThreatsRequest
} from '../../../../services/cyber-api';
import { StateApi } from '../../../../services/state-api';
import { UxBlade } from '../../../../components/layout/ux-blade/ux-blade';
import { Router } from 'aurelia-router';
import { AuthenticationProvider } from '../../../../providers/authentication-provider';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EventKeys } from '../../../../enums/event-keys';
import { ThreatAction } from '../../../../enums/threat-action';

@autoinject()
export class SelectedThreatActions {
    @bindable() private selectedThreatsLastChanged: number;
    @bindable() private selectedThreats: ThreatSummary[];
    private allowActions: boolean;
    private allowIgnoreOrAddToCase: boolean;
    private allowFlag: boolean;
    private allowUnflag: boolean;
    private loading: boolean;
    private caseBlade: UxBlade;
    private cases: CaseBook[];

    public constructor(
        private state: StateApi,
        private threatsApi: ThreatsApiClient,
        private casesApi: CasesApiClient,
        private router: Router,
        private authenticationProvider: AuthenticationProvider,
        private eventAggregator: EventAggregator
    ) {
    }

    private selectedThreatsLastChangedChanged(): void {
        setTimeout(() => {
            this.allowActions = this.validate();
            this.allowIgnoreOrAddToCase = this.selectedThreats.filter(x => x.alertType === AlertType.EdrAlert).length === 0;
            this.allowFlag = this.selectedThreats.filter(x => x.flagged === false).length > 0;
            this.allowUnflag = this.selectedThreats.filter(x => x.flagged === true).length > 0;
        });
    }

    private async close(): Promise<void> {
        const multiple = this.selectedThreats.length > 1;

        if (!this.validate()) {
            Toast.warning(`Selected threat${multiple ? 's' : ''} cannot be closed as ${multiple ? 'they are' : 'it\'s'} already closed or ignored`);
            return;
        }

        const dialog = await Swal.fire({
            title: `Mark ${this.selectedThreats.length} threats as closed?`,
            html: multiple
                ? 'Closing the selected threats means you consider the threats to be "handled" and no further actions can be applied. If the threats are still detected afterwards, they will pop back up in the future as new threats.'
                : 'Closing the selected threat means you consider the threat to be "handled" and no further actions can be applied. If the threat is still detected afterwards, it will pop back up in the future as a new threat.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, close',
            input: 'textarea',
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        });

        // Short-circuit when the dialog was dismissed
        if (dialog.dismiss)
            return;

        // Notify consumers that the action is about to be executed
        this.publishActionEvent(EventKeys.beforeThreatsAction, ThreatAction.Close, dialog.value);

        this.loading = true;

        try {
            const request = new CloseBulkThreatsRequest({
                ids: this.selectedThreats.map(threat => threat.id),
                comment: dialog.value
            });
            await this.threatsApi.closeBulk(this.state.company(), request);

            this.selectedThreats.forEach(x => x.status = 'Closed');

            // Notify consumers that the action has been executed
            this.publishActionEvent(EventKeys.onThreatsAction, ThreatAction.Close, dialog.value);

            notie.alert({
                position: 'bottom',
                text: multiple
                    ? 'OK, selected threats have been closed'
                    : 'OK, selected threat has been closed',
                type: 'success'
            });
        } catch (error) {
            notie.alert({ position: 'bottom', text: `Oops, ${error}`, type: 'error' });
        } finally {
            this.afterThreatAction();
        }
    }

    private async ignore(): Promise<void> {
        const multiple = this.selectedThreats.length > 1;

        if (!this.validate()) {
            Toast.warning(`Selected threat${multiple ? 's' : ''} cannot be ignored as ${multiple ? 'they are' : 'it\'s'} already closed or ignored`);
            return;
        }

        const dialog = await Swal.fire({
            title: multiple
                ? `Ignore ${this.selectedThreats.length} threats?`
                : `Ignore this threat?`,
            html: multiple
                ? 'Ignoring the selected threats means you consider these threats to be "false positives". No further actions can be made on ignored threats.'
                : 'Ignoring this threat means you consider this threat to be a "false positive". No further actions can be made on ignored threats.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, ignore',
            input: 'textarea',
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        });

        // Short-circuit when the dialog was dismissed
        if (dialog.dismiss)
            return;

        // Notify consumers that the action is about to be executed
        this.publishActionEvent(EventKeys.beforeThreatsAction, ThreatAction.Ignore, dialog.value);

        this.loading = true;

        try {
            const request = new IgnoreBulkThreatsRequest({
                ids: this.selectedThreats.map(threat => threat.id),
                comment: dialog.value
            });
            await this.threatsApi.ignoreBulk(this.state.company(), request);

            this.selectedThreats.forEach(x => x.status = 'Rejected');

            // Notify consumers that the action has been executed
            this.publishActionEvent(EventKeys.onThreatsAction, ThreatAction.Ignore, dialog.value);

            notie.alert({
                position: 'bottom',
                text: multiple
                    ? 'OK, selected threats have been ignored'
                    : 'OK, selected threat has been ignored',
                type: 'success'
            });
        } catch (error) {
            notie.alert({ position: 'bottom', text: `Oops, ${error}`, type: 'error' });
        } finally {
            this.afterThreatAction();
        }
    }

    private async flag(): Promise<void> {
        const multiple = this.selectedThreats.length > 1;

        this.loading = true;

        try {
            const request = new FlagBulkThreatsRequest({
                ids: this.selectedThreats.map(threat => threat.id)
            });
            await this.threatsApi.flagBulk(this.state.company(), request);

            this.selectedThreats.forEach(x => x.flagged = true);

            // Notify consumers that the action has been executed
            this.publishActionEvent(EventKeys.onThreatsAction, ThreatAction.Flag);

            notie.alert({
                position: 'bottom',
                text: multiple
                    ? 'OK, selected threats have been flagged'
                    : 'OK, selected threat has been flagged',
                type: 'success'
            });
        } catch (error) {
            notie.alert({ position: 'bottom', text: `Oops, ${error}`, type: 'error' });
        } finally {
            this.afterThreatAction();
        }
    }

    private async unflag(): Promise<void> {
        const multiple = this.selectedThreats.length > 1;

        this.loading = true;

        try {
            const request = new UnflagBulkThreatsRequest({
                ids: this.selectedThreats.map(threat => threat.id)
            });
            await this.threatsApi.unflagBulk(this.state.company(), request);

            this.selectedThreats.forEach(x => x.flagged = false);

            // Notify consumers that the action has been executed
            this.publishActionEvent(EventKeys.onThreatsAction, ThreatAction.Unflag);

            notie.alert({
                position: 'bottom',
                text: multiple
                    ? 'OK, selected threats have been unflagged'
                    : 'OK, selected threat has been unflagged',
                type: 'success'
            });
        } catch (error) {
            notie.alert({ position: 'bottom', text: `Oops, ${error}`, type: 'error' });
        } finally {
            this.afterThreatAction();
        }
    }

    private validate(): boolean {
        return this.selectedThreats.length > 0
            && this.selectedThreats.every(threat =>
                threat.status !== 'Rejected'
                && threat.status !== 'Closed'
            );
    }

    private publishActionEvent(eventKey: EventKeys, action: ThreatAction, comment?: string): void {
        // Publish event notifying parties that the threats have been updated and should be updated in the UI
        const selectedThreatsClone = [...this.selectedThreats];
        this.eventAggregator.publish(eventKey, { threats: selectedThreatsClone, action, comment });
    }

    private afterThreatAction(): void {
        this.loading = false;
        this.clear();
    }

    private clear(): void {
        this.selectedThreats.splice(0, this.selectedThreats.length);
    }

    private addToCase = async (caseBook: CaseBookLightweightExtended): Promise<void> => {
        const multiple = this.selectedThreats.length > 1;
        const response = await Swal.fire({
            title: multiple
                ? `Add ${this.selectedThreats.length} threats to Case`
                : `Add threat to Case`,
            html: multiple
                ? `Are you sure you want to add these threats to ${caseBook.title}?`
                : `Are you sure you want to add this threat to ${caseBook.title}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, add',
        });

        if (response.value) {
            await this.attachThreatsToCase(caseBook);
            this.selectedThreats.forEach(x =>
                x.caseBookIds.push(caseBook.id)
            );
            this.afterThreatAction();
        }
    };

    private createCase(): void {
        this.caseBlade.show();
    }

    private onCaseBladeHide = async (caseBook: CaseBookLightweightExtended): Promise<void> => {
        this.caseBlade.hide();

        // If a CaseBook is present, a CaseBook was created in the CaseBlade. We now have to attach the threat to the
        // newly created CaseBook.
        if (caseBook) {
            await this.attachThreatsToCase(caseBook);
            this.selectedThreats.forEach(x =>
                x.caseBookIds.push(caseBook.id)
            );
            this.navigateToCase(caseBook);
            this.afterThreatAction();
        }
    };

    private async attachThreatsToCase(caseBook: CaseBookLightweightExtended): Promise<void> {
        try {
            this.loading = true;
            const referencedEntities: CaseBookReferencedEntity[] = [];
            for (const threat of this.selectedThreats) {
                const referencedEntity = new CaseBookReferencedEntity({
                    createdAt: new Date(),
                    createdBy: this.authenticationProvider.profile.sub,
                    entityId: threat.shortId,
                    entityType: CaseBookReferencedEntityTypes.Threat,
                    label: threat.shortId
                });
                referencedEntities.push(referencedEntity);
            }
            await this.casesApi.attachEntities(caseBook.id, this.state.company(), referencedEntities);
            Toast.success(`Successfully added threats to Case`);
        } catch (error) {
            Toast.error(`Could not add threats to Case`);
            this.loading = false;
        }
    }

    private navigateToCase(caseBook: CaseBook): void {
        // As routing to a child route of a different parent route is not possible, navigate to the URL fragment instead
        // See https://github.com/aurelia/router/issues/89
        // where https://github.com/aurelia/router/issues/89#issuecomment-282079392 could be a solution.
        this.router.navigate(`cases/${caseBook.id}`);
    }
}
